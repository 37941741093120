import React from "react"

import Layout from "../components/layout"

const ExperiencePage = () => {
  return (
    <Layout>
      <main role="main" className="bg-white">
        <div className="relative building-cover">
          <div className="pv6 mt0 center mw9 ph3">
            <h2 className="relative z-1 dib white frank ttu fw4 f4 mv4 tracked-mega">
              Un miglior brand si traduce in più clienti
            </h2>
            <h1 className="relative z-1 pb3 playfair f2 f1-m f1-l fw4 white">
              Fai crescere la tua attività
              <br />
              ora puoi
            </h1>
            <h2 className="relative z-1 dib white frank fw3 f2">
              Ottimizza la gestione dei dati sensibili
            </h2>
          </div>
        </div>

        <div className="mw9 center bg-light-color pa4">
          <h2
            className="f1 playfair fw4 tc pv2"
            style={{ marginBottom: "1rem" }}
          >
            Coinvolgi i tuoi Clienti
          </h2>
          <p className="center mw8 frank fw4 f3 mv4 lh-copy">
            I tuoi Clienti sono importanti: se si sentiranno coinvolti ed al
            sicuro, avremo centrato il nostro obbiettivo.
          </p>

          <div className="flex flex-wrap mv6">
            <h4
              className="dn db-m center fw4 f2 playfair"
              style={{ marginBottom: "1rem" }}
            >
              Tutto sotto controllo in ogni istante
            </h4>
            <div className="w-100 w-50-ns">
              <img
                src="/images/control.jpeg"
                className="db center w-100"
                style={{ filter: "grayscale(100%)" }}
                alt=""
              />
            </div>
            <div className="w-100 w-50-ns ph3">
              <h4
                className="db dn-m fw4 f2 playfair"
                style={{ marginBottom: "0" }}
              >
                Tutto sotto controllo in ogni istante
              </h4>
              <p className="mt4 frank fw4 f4 measure lh-copy">
                Con la timeline di Time2Client lo stato della pratica è sempre
                sotto controllo.
              </p>
              <p className="mt4 frank fw4 f4 measure lh-copy">
                Tutte le scadenze future e gli eventi passati sono a portata di
                click.
              </p>
              <p className="mt4 frank fw4 f4 measure lh-copy">
                Visualizzare la storia e l'andamento della propria pratica non è
                mai stato così semplice.
              </p>
            </div>
          </div>

          <div className="flex flex-wrap-reverse mv6">
            <div className="w-100 w-50-ns ph3">
              <h4
                className="db dn-m fw4 f2 playfair"
                style={{ marginBottom: "0" }}
              >
                Autonomia nella gestione dell'account Cliente
              </h4>
              <p className="mt4 frank fw4 f4 measure lh-copy">
                Garantiamo piena riservatezza al tuo cliente che può gestire in
                autonomia il proprio account. Le sue password sono criptate e
                inaccessibili ed in più può raggiungere tutte le informazioni
                che lo riguardano in un click.
              </p>
              <p className="mt4 frank fw4 f4 measure lh-copy">
                Questo ti permetterà di risparmiare tempo e di lavorare di più
                su quello che conta o, semplicemente, di goderti il tempo
                libero.
              </p>
            </div>
            <div className="w-100 w-50-ns">
              <img
                src="/images/client_overview.jpeg"
                className="db center w-100"
                style={{ filter: "grayscale(100%)" }}
                alt=""
              />
            </div>
            <h4
              className="dn db-m center fw4 f2 playfair"
              style={{ marginBottom: "1rem" }}
            >
              Autonomia nella gestione dell'account Cliente
            </h4>
          </div>
        </div>

        <div className="mw9 center pa4">
          <h2
            className="f1 playfair fw4 tc pv2"
            style={{ marginBottom: "1rem" }}
          >
            Collabora e condividi con i tuoi colleghi
          </h2>
          <p className="center mw8 frank fw4 f3 mv4 lh-copy">
            La gestione di una pratica richiede spesso la collaborazione tra più
            soggetti. Time2Client ti permette di interagire e collaborare in
            maniera agile e semplice, scegliendo tra diversi livelli di
            condivisione, a seconda del ruolo assunto da ciascuno nell’ambito
            della pratica.
          </p>

          <div className="flex flex-wrap mv6">
            <h4
              className="dn db-m center fw4 f2 playfair"
              style={{ marginBottom: "1rem" }}
            >
              Crea la tua rete di collaboratori
            </h4>
            <div className="w-100 w-50-ns">
              <img
                src="/images/invite.jpeg"
                className="db center w-100"
                style={{ filter: "grayscale(100%)" }}
                alt=""
              />
            </div>
            <div className="w-100 w-50-ns ph3">
              <h4
                className="db dn-m fw4 f2 playfair"
                style={{ marginBottom: "0" }}
              >
                Crea la tua rete di collaboratori
              </h4>
              <p className="mt4 frank fw4 f4 measure lh-copy">
                Invita i colleghi co-mandatari, i collaboratori ed i consulenti
                di fiducia a far parte di un nuovo livello di collaborazione.
              </p>
              <p className="mt4 frank fw4 f4 measure lh-copy">
                Organizza tutta la documentazione relativa alle pratiche in un
                unico luogo digitale, senza più allegati da copiare o mail da
                trovare.
              </p>
              <p className="mt4 frank fw4 f4 measure lh-copy">
                Il tutto con la garanzia del pieno rispetto della privacy del
                tuo Cliente, grazie ai più avanziati sistemi di cryptazione.
              </p>
            </div>
          </div>

          <div className="flex flex-wrap-reverse mv6">
            <div className="w-100 w-50-ns ph3">
              <h4
                className="db dn-m fw4 f2 playfair"
                style={{ marginBottom: "0" }}
              >
                Condividi l'intera pratica, o un unico documento
              </h4>
              <p className="mt4 frank fw4 f4 measure lh-copy">
                Time2Client ti permette di condividere documenti di una pratica
                con il network che hai creato, mantenendo il controllo su cosa
                vuoi condividere con chi, nel pieno rispetto dei diversi ruoli
                professionali e della privacy dei tuoi Clienti.
              </p>
            </div>
            <div className="w-100 w-50-ns">
              <img
                src="/images/share.jpeg"
                className="db center w-100"
                style={{ filter: "grayscale(100%)" }}
                alt=""
              />
            </div>
            <h4
              className="dn db-m center fw4 f2 playfair"
              style={{ marginBottom: "1rem" }}
            >
              Condividi l'intera pratica, o un unico documento
            </h4>
          </div>
        </div>

        <div
          className="relative pv5 mt7 bg-light-color footer-cover"
          style={{ backgroundColor: "#2b2928" }}
        >
          <div className="mw9 pb5 ph3 tc-ns center">
            <p className="white f4 f3-m f2-l mv0 pv0 playfair">
              Time2Client è in fase di anteprima e disponibile solo su invito.
            </p>
            <p className="white f4 f3-m f2-l mv0 pv0 playfair">
              Se vuoi essere tra i primi Clienti, inserisci il tuo indizzo mail
              qui sotto.
            </p>
          </div>
          <form
            action="/it/registration/interest"
            className="bg-sec-color white mw7 center pa4 br2-ns ba b--black-10"
            method="post"
          >
            <input
              name="_csrf_token"
              type="hidden"
              value="MChMWipSS1EkGWtbDxcHIQk9fgZULw5L_x9izbf2RHRh6GmEbeMN7w9s"
            />
            <fieldset className="cf bn ma0 pa0">
              <legend className="pa0 f5 f4-ns mb3">
                Voglio provare Time2Client
              </legend>
              <div className="cf">
                <label className="clip" htmlFor="lawyer_email">
                  Email
                </label>
                <input
                  className="f6 f5-l input-reset bn fl black-80 bg-white pa3 lh-solid w-100 w-75-m w-80-l br2-ns br--left-ns"
                  id="lawyer_email"
                  name="lawyer[email]"
                  placeholder="avvocato@studiolegale.it"
                  type="email"
                />
                <button
                  className="frank f5 f4-l button-reset fl pv3 tc bn bg-animate bg-black-70 hover-bg-prim-color white pointer w-100 w-25-m w-20-l br2-ns br--right-ns"
                  type="submit"
                >
                  Invia
                </button>{" "}
              </div>
              <p className="lato f5 pt3">Tienimi informato</p>
            </fieldset>
          </form>
        </div>
      </main>
    </Layout>
  )
}

export default ExperiencePage
